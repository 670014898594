import axios from "axios";

const API_URL = 'https://backend.metahome.li/';
const HEADERS = {
    'Content-Type': 'application/json'
};

export const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: HEADERS
});