import React, { useEffect, useState } from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import Lottie from 'react-lottie';
import { web3Enable, web3Accounts } from '@polkadot/extension-dapp';

import {
  retriveNfts,
  getUserData,
  buyNftEvent,
  userOwnedNftEvent,
  setCurrentNftEvent,
  sellNftEvent,
  getAllAvatars,
  setActiveAvatar,
  avatarCollectionEvent,
} from './event.handler';
import loader from '../../assets/loader.gif';
import * as animationData from '../../assets/Loading.json';
import { getWorldByUserAndWorld } from '../../controller/world.controller';
import { getUserDetails } from '../../controller/user.controller';
import { getUserAccountDetails } from '../../service/storageReader';
import { convertBalance } from '../../service/Transactions/utils';

const MetaPort = () => {
  const dataUrl = `https://wl-unity.s3.ap-south-1.amazonaws.com/ReactUnity/MetaPort/beta/MetaPort.data`;
  const loaderUrl = `https://wl-unity.s3.ap-south-1.amazonaws.com/ReactUnity/MetaPort/beta/MetaPort.loader.js`;
  const frameworkUrl = `https://wl-unity.s3.ap-south-1.amazonaws.com/ReactUnity/MetaPort/beta/MetaPort.framework.js`;
  const codeUrl = `https://wl-unity.s3.ap-south-1.amazonaws.com/ReactUnity/MetaPort/beta/MetaPort.wasm`;

  const [userAccountId, setUserAccountId] = useState('');

  // here I am adding the wallet connect functionality for the user to go to metagallery directly
  const handleConnectWallet = async () => {
    console.log('inside handle wallet connect');
    try {
      const injectedExtension = await web3Enable('Socialli');
      if (injectedExtension.length > 0) {
        const accounts = await web3Accounts();
        const selectedAccount = await getSelectedAccount(accounts);
        console.log('got selected account = ', selectedAccount);
        setUserAccountId(selectedAccount.address);
      } else {
        alert('no wallet extension found!');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getSelectedAccount = async accounts => {
    const firstAccount = accounts[0];
    let selectedAccount = null;
    for (let i = 0; i < accounts.length; i++) {
      const account = accounts[i];
      const result = await getUserDetails(account.address);
      if (result.status) {
        const thisUserDetails = result.data;
        const defaultAvatar = await getWorldByUserAndWorld(account.address, 'Default');
        const worldAvatar = await getWorldByUserAndWorld(account.address, 'MetaHome');
        console.log('got account and avatar details');
        selectedAccount = account;
        if (defaultAvatar.status || worldAvatar.status) {
          thisUserDetails.avatarUrl = worldAvatar.status ? worldAvatar.data.avatarUrl : defaultAvatar.data.avatarUrl;
        }
        break;
      }
    }
    return selectedAccount ? selectedAccount : firstAccount;
  };

  const {
    unityProvider,
    isLoaded,
    sendMessage,
    addEventListener,
    removeEventListener,
    loadingProgression,
  } = useUnityContext({
    dataUrl,
    loaderUrl,
    frameworkUrl,
    codeUrl,
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    const init = async () => {
      await handleConnectWallet();
    };
    if (!userAccountId) {
      init();
    }
  }, [userAccountId]);

  useEffect(() => {
    if (isLoaded) {
      getAllAvatars(addEventListener, sendMessage, userAccountId);
      setActiveAvatar(addEventListener, sendMessage, userAccountId);
      avatarCollectionEvent(addEventListener, sendMessage, userAccountId);
    }
  }, [isLoaded]);

  const loadingPercentage = Math.round(loadingProgression * 100);

  if (!userAccountId) {
    return (
      <div>
        <Loader>Loading....</Loader>
      </div>
    );
  }

  return (
    <div className="bg-black-variant">
      {isLoaded === false && (
        <div className="sticky top-0 flex flex-col items-center h-full w-full bg-black-variant">
          <Lottie options={defaultOptions} />
          <h2 className="text-gray-200 mt-[-15%] text-[20px] font-main font-[400] text-center tracking-wide">
            Downloading Assets... ({loadingPercentage}%)
          </h2>
        </div>
      )}

      {<Unity unityProvider={unityProvider} className="h-screen w-full" />}
      {/* {isMetaporting && <Unity unityProvider={metaportProvider} className="h-screen w-full" />} */}
    </div>
  );
};

export default MetaPort;
